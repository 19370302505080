<script lang="ts">;
export let square;
</script>

<style lang="scss">.square {
  background-color: white;
  width: 100%;
  min-height: 130px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square.drawn {
  background-color: lightgray;
}</style>

<div class="square {square?.drawn ? 'drawn' : ''}">
    <p>{square?.text || 'Square not assigned'}</p>
</div>
