<script lang="ts">import Square from './board/square.svelte';
import { squaresWithDrawInfo, claimBingo, isBingo, winner } from '../contract-methods';
import { session } from '../session';
;
function squareAtIndex(squares, row, column) {
    return squares === null || squares === void 0 ? void 0 : squares.find((square) => {
        return square.x.toNumber() === column + 1 && square.y.toNumber() === row + 1;
    });
}
function handleVictoryClaim(event) {
    event.preventDefault();
    claimBingo($session.value, $isBingo.winType, $isBingo.winningSquares);
}
</script>

<style lang="scss">.board {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-gap: 1px;
  background-color: black;
  margin: 50px auto;
  border: 1px solid black;
  max-width: 800px;
  min-height: 500px;
}

.board p.no-squares {
  grid-column: 1/span 5;
  grid-row: 1/span 5;
  background-color: white;
  height: 100%;
  margin: 0;
  padding: 100px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}</style>

{#if $winner}
    <h2>
        {#if $winner.toString() === $session.value?.auth?.actor?.toString()}
            Congratulations! You won!!!
        {:else}
            {$winner.toString()} won!! Better luck next time!
        {/if}
    </h2>
{:else if $isBingo?.win}
    <h2>
        Bingo!!!!
        <button on:click={handleVictoryClaim}>Claim Victory Now</button>
    </h2>
{/if}

<div class="board">
    {#if !$squaresWithDrawInfo || $squaresWithDrawInfo?.length === 0}
        <p class="no-squares">No squares yet, ask your admin to add some for you.</p>
    {:else}
        {#each Array(5) as _, row}
            {#each Array(5) as _, column}
                <Square square={squareAtIndex($squaresWithDrawInfo, row, column)} />
            {/each}
        {/each}
    {/if}
</div>
