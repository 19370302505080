<script lang="ts">import { currentUser, logout } from '~/session';
</script>

<style lang="scss">.navbar {
  display: inline;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  align-items: center;
}
.navbar a {
  cursor: pointer;
  color: blue;
}</style>

<div class="navbar">
    <p>
        Logged in as {$currentUser.value?.actor.toString()} |
        <a href={'#'} on:click={logout}>Logout</a>
    </p>
</div>
