<script lang="ts">import { currentPlayer, isAdminPlayer } from '~/contract/players';
import { login, logout, currentUser } from '~/session';
import AdminInterface from '~/components/admin.svelte';
import Board from '~/components/board.svelte';
import NavBar from '~/components/navbar.svelte';
import Draws from '~/components/draws.svelte';
import Players from '~/components/players.svelte';
</script>

<style lang="scss" global>:global(:root) {
  --text: black;
  --secondary-text: gainsboro;
  --background-color: white;
  --primary-color: teal;
  --primary-color-text: white;
  --gutter-width: 14ch;
}
@media (max-width: 30em) {
  :global(:root) {
    --gutter-width: 1ch;
  }
}

:global(*) {
  box-sizing: border-box;
}

:global(body) {
  background-color: var(--background-color);
  color: var(--text);
  padding: 0;
  margin: 0;
}

:global(html),
:global(button),
:global(input) {
  font-family: Courier, monospace;
  font-size: 13px;
  line-height: 1;
}

:global(button),
:global(input[type=submit]) {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
  cursor: pointer;
  border: 0;
  padding: 0.2em 0.4em;
}
:global(button:hover),
:global(input[type=submit]:hover) {
  filter: brightness(1.5);
}

:global(input[type=text]) {
  border: 0;
  padding: 0.2em 0.4em;
}

:global(main) {
  text-align: center;
  padding-top: 100px;
}

:global(.grid) {
  display: grid;
  grid-template-columns: 300px auto;
}
:global(.grid) :global(.left-column) {
  width: 100%;
  border-right: 1px solid gainsboro;
}</style>

<main>
    {#if !$currentUser.value}
        <h3>Login in to use this application.</h3>
        <button on:click={login}> Login now </button>
    {:else if !$currentPlayer}
        <h3>You do not have access to this bingo game</h3>
        <button on:click={logout}> Logout </button>
    {:else}
        <NavBar />
        <div class="grid">
            <div class="left-column">
                {#if !!$isAdminPlayer}
                    <AdminInterface />
                {/if}
                <Draws />
                <Players />
            </div>
            <div class="board-column">
                <Board />
            </div>
        </div>
    {/if}
</main>
