<script lang="ts">import AutoComplete from 'simple-svelte-autocomplete';
import { bingoStrings } from '~/constants';
import { session } from '~/session';
import { draw } from '~/contract-methods';
let textToDraw = '';
let drawing = false;
let error = null;
function handleSubmit(event) {
    event.preventDefault();
    if (textToDraw === '') {
        error = new Error('Please enter a valid text');
        return;
    }
    drawing = true;
    error = null;
    draw($session.value, textToDraw)
        .catch((e) => {
        error = e;
    })
        .finally(() => {
        textToDraw = '';
        drawing = false;
    });
}
</script>

<style lang="scss">.draw {
  padding: 20px 0;
  background: floralwhite;
  width: 100%;
  border-top: 0.2em solid var(--primary-color);
  border-bottom: 0.2em solid var(--primary-color);
}
.draw form {
  width: 100%;
}
.draw form :global(input) {
  margin: 3px;
  width: 90%;
}
.draw form :global(.autocomplete) {
  width: 100%;
}
.draw form :global(.autocomplete.select.is-fullwidth::after) {
  display: none;
}</style>

<div class="draw">
    {#if drawing}
        <p>Drawing word...</p>
    {:else}
        <form on:submit={handleSubmit}>
            <AutoComplete
                noInputStyles
                placeholder="Select a string to draw.."
                items={bingoStrings}
                bind:selectedItem={textToDraw}
            />

            <input type="submit" value="Draw" />
            {#if error}
                <p>Unable to draw word: <b>{error}</b></p>
            {/if}
        </form>
    {/if}
</div>
