<script lang="ts">import { session } from '~/session';
import { resetGame } from '~/contract-methods';
let resetting = false;
let error = null;
function handleSubmit(event) {
    event.preventDefault();
    resetting = true;
    error = null;
    resetGame($session.value)
        .catch((e) => {
        console.log({ e });
        error = e;
    })
        .finally(() => {
        resetting = false;
    });
}
</script>

<style lang="scss">form {
  padding: 10px;
  width: 100%;
  background: floralwhite;
  border-top: 0.2em solid var(--primary-color);
}</style>

<div class="post">
    {#if resetting}
        <p>Resetting game...</p>
    {:else}
        <form on:submit={handleSubmit}>
            <input type="submit" value="Reset Game" />
            {#if error}
                <p>Unable to reset game: <b>{error}</b></p>
            {/if}
        </form>
    {/if}
</div>
