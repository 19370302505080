import {Struct, UInt64, Name, UInt16} from '@greymass/eosio'

@Struct.type('square_with_draw_info')
export class SquareWithDrawInfo extends Struct {
    @Struct.field(UInt64) id!: UInt64
    @Struct.field(Name) owner!: Name
    @Struct.field(UInt16) x!: UInt16
    @Struct.field(UInt16) y!: UInt16
    @Struct.field('string') text!: string
    @Struct.field('bool') drawn!: boolean
}
