import type {AnyAction, LinkSession, Name} from 'anchor-link'
import {UInt64} from 'anchor-link'
import {writable as writableResult} from 'svelte-result-store'
import {derived} from 'svelte/store'

import * as types from '../contract-types'
import {link, session, currentUser} from '../session'
import {contract} from '../config'
import {bingoStrings} from '~/constants'
import {addSquares, squares} from './squares'
import {draws} from './draws'

export async function addPlayer(session: LinkSession, account: Name, admin?: boolean) {
    const data = types.Addplayer.from({
        account,
        admin: admin || false,
    })
    const action: AnyAction = {
        account: contract,
        name: 'addplayer',
        authorization: [session.auth],
        data,
    }

    try {
        await session.transact({action})
    } catch (error) {
        console.log(`Error on transact: ${(error as Error).message}`)
    }
}

export async function delPlayer(session: LinkSession, account: Name) {
    const data = types.Delplayer.from({
        account,
    })
    const action: AnyAction = {
        account: contract,
        name: 'delplayer',
        authorization: [session.auth],
        data,
    }

    try {
        await link.transact({action})
    } catch (error) {
        console.log(`Error on transact: ${(error as Error).message}`)
    }
}

export async function claimBingo(
    session: LinkSession,
    winType: UInt64,
    winningSquares: types.WinningSquare[]
) {
    const data = types.Bingo.from({
        account: session.auth.actor,
        win_type: winType,
        winning_squares: winningSquares,
    })
    const action: AnyAction = {
        account: contract,
        name: 'bingo',
        authorization: [session.auth],
        data,
    }
    try {
        await session.transact({action})
    } catch (error) {
        console.log(`Error on transact: ${(error as Error).message}`)
    }
}

export function loadPlayers() {
    return link.client.v1.chain.get_table_rows({
        code: contract,
        table: 'players',
        type: types.PlayerRow,
        limit: 100,
        key_type: 'i64',
        index_position: 'primary',
    })
}

export const players = writableResult<types.PlayerRow[]>((set, error) => {
    let timer: any
    var running = true
    const load = () => {
        loadPlayers()
            .then(({rows}) => {
                set(rows)
            })
            .catch(error)
            .finally(() => {
                if (running) {
                    timer = setTimeout(load, 5000)
                }
            })
    }
    load()
    return () => {
        running = false
        clearInterval(timer)
    }
})

export const currentPlayer = derived([players, session], ([$players, $session]) => {
    if (!$session) {
        return null
    }

    const {PlayerRow} = types

    return $players.value?.find((player) => {
        return PlayerRow.from(player).owner.toString() === $session.value?.auth?.actor.toString()
    })
})

export const isAdminPlayer = derived(
    [currentPlayer, currentUser],
    ([$currentPlayer, $currentUser]) => {
        return $currentPlayer?.admin || contract === $currentUser.value?.actor?.toString()
    }
)

export const isBingo = derived(
    [currentPlayer, draws, squares],
    ([$currentPlayer, $draws, $squares]): {
        win: boolean
        winType?: UInt64
        winningSquares?: types.WinningSquare[]
    } => {
        if (!$currentPlayer || !$draws.value || !$squares.value) {
            return {
                win: false,
            }
        }

        const drawnPlayerSquares = $squares.value.filter((square) => {
            return (
                square.owner.toString() === $currentPlayer.owner.toString() &&
                ($draws.value || []).map((draw) => draw.text).includes(square.text)
            )
        })

        if (drawnPlayerSquares.length < 5) {
            return {
                win: false,
            }
        }

        const rows: number[] = new Array(5).fill(0)
        const columns: number[] = new Array(5).fill(0)
        const crossHalfs: number[] = new Array(2).fill(0)

        const {WinningSquare} = types

        for (const square of drawnPlayerSquares) {
            rows[square.y.toNumber() - 1] += 1
            columns[square.x.toNumber() - 1] += 1

            if (square.x.toNumber() === square.y.toNumber()) {
                crossHalfs[0] += 1
            } else if (square.x.toNumber() + square.y.toNumber() === 4) {
                crossHalfs[1] += 1
            }

            const {win, winType} = containsBingo(rows, columns, crossHalfs)

            if (win && winType) {
                return {
                    win,
                    winType: UInt64.from(winType) as UInt64,
                    winningSquares: drawnPlayerSquares
                        .filter((square) => {
                            if (winType < 6) {
                                return square.y.toNumber() === winType
                            } else if (winType < 11) {
                                return square.x.toNumber() === Number(winType) - 5
                            } else if (winType === 11) {
                                return square.y.toNumber() === square.x.toNumber()
                            } else {
                                return square.y.toNumber() + square.x.toNumber() === 4
                            }
                        })
                        .map(
                            (square): types.WinningSquare => {
                                return WinningSquare.from({
                                    square_id: UInt64.from(square.id),
                                    draw_id: UInt64.from(
                                        $draws.value?.find((draw) => draw.text === square.text)?.id
                                    ),
                                })
                            }
                        ),
                }
            }
        }

        return {
            win: false,
        }
    }
)

function containsBingo(
    rows: number[],
    columns: number[],
    crossHalfs: number[]
): {win: boolean; winType?: Number} {
    if (rows.some((row) => row === 5)) {
        return {
            win: true,
            winType: rows.indexOf(5) + 1,
        }
    } else if (columns.some((column) => column === 5)) {
        return {
            win: true,
            winType: columns.indexOf(5) + 6,
        }
    } else if (crossHalfs.some((crossHalf) => crossHalf === 5)) {
        return {
            win: true,
            winType: crossHalfs.indexOf(5) + 11,
        }
    }

    return {
        win: false,
    }
}

export async function shuffle(session: LinkSession) {
    const {Square} = types

    for (const [, player] of ((await playersValue()) || []).entries()) {
        const shuffledStrings = shuffleStrings(bingoStrings)

        await addSquares(
            session,
            player.owner,
            shuffledStrings.map((string, index) =>
                Square.from({
                    text: string,
                    x: (index % 5) + 1,
                    y: Math.ceil((index + 1) / 5),
                })
            )
        )
    }
}

export function playersValue(): Promise<types.PlayerRow[] | undefined> {
    return new Promise((resolve) => {
        let unsubscribe: () => void | undefined

        unsubscribe = players.subscribe((value) => {
            unsubscribe && unsubscribe()

            resolve(value.value)
        })
    })
}

function shuffleStrings(strings: string[]) {
    let currentIndex = strings.length,
        randomIndex

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        ;[strings[currentIndex], strings[randomIndex]] = [
            strings[randomIndex],
            strings[currentIndex],
        ]
    }

    return strings
}
