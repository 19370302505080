import {AnyAction, LinkSession, Name, UInt64} from 'anchor-link'
import {writable as writableResult} from 'svelte-result-store'
import {derived} from 'svelte/store'

import * as types from '../contract-types'
import {link, session} from '../session'
import {contract} from '../config'

import {draws} from './draws'
import {SquareWithDrawInfo} from '~/types'

export async function addSquares(session: LinkSession, account: Name, squares: types.Square[]) {
    const data = types.Addsquares.from({
        admin: session.auth.actor,
        account,
        newsquares: squares,
    })
    const action: AnyAction = {
        account: contract,
        name: 'addsquares',
        authorization: [session.auth],
        data,
    }
    try {
        await session.transact({action})
    } catch (error) {
        console.log(`Error on transact: ${(error as Error).message}`)
    }
}

async function loadSquares(lowerBound: UInt64) {
    let response = await link.client.v1.chain.get_table_rows({
        code: contract,
        table: 'squares',
        type: types.SquareRow,
        limit: 100,
        lower_bound: lowerBound,
        key_type: 'i64',
        index_position: 'primary',
    })
    return response
}

export const squares = writableResult<types.SquareRow[]>((set, error) => {
    let headId = UInt64.from(0)
    let timer: any
    var running = true
    const load = () => {
        loadSquares(headId)
            .then(({rows}) => {
                set(rows)
            })
            .catch(error)
            .finally(() => {
                if (running) {
                    timer = setTimeout(load, 5000)
                }
            })
    }
    load()
    return () => {
        running = false
        clearInterval(timer)
    }
})

export const squaresWithDrawInfo = derived(
    [squares, draws, session],
    ([$squares, $draws, $session]) => {
        if (!$session?.value) {
            return []
        }

        try {
            const {SquareRow, DrawRow} = types
            const SquareWithDrawInfoClass = SquareWithDrawInfo

            const squaresWithDrawInfo = $squares.value
                ?.map((square) => SquareRow.from(square))
                .filter((squareObject) => {
                    return squareObject.owner.toString() === $session.value?.auth?.actor.toString()
                })
                .map((squareObject) => {
                    const draw = ($draws.value || []).find(
                        (draw) => DrawRow.from(draw).text === squareObject.text
                    )

                    return SquareWithDrawInfoClass.from({
                        ...squareObject,
                        drawn: !!draw,
                    })
                })

            return squaresWithDrawInfo
        } catch (error) {
            console.error(`Error in squaresWithDrawInfo: ${error}`)
            return []
        }
    }
)

export const allStrings = derived(squares, ($squares) => {
    const {SquareRow} = types

    return Array($squares)
        .map((square) => SquareRow.from(square).text)
        .filter((value, index, self) => self.indexOf(value) === index)
})
