<script lang="ts">import Draw from './admin/forms/draw.svelte';
import ResetGame from './admin/forms/reset-game.svelte';
import Shuffle from './admin/forms/shuffle.svelte';
</script>

<style lang="scss">div {
  border-top: 0.2em solid var(--primary-color);
}</style>

<div>
    <h2>Admin UI</h2>
    <ResetGame />
    <Shuffle />
    <Draw />
    <!--    <AddPlayer />-->
</div>
