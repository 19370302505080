<script lang="ts">import { players,
// delPlayer
 } from '~/contract-methods';
// import {isAdminPlayer} from '~/contract/players'
// function removePlayer(player: Name) {
//     delPlayer($session.value!, player)
// }
</script>

<style lang="scss">h2 {
  text-decoration: underline;
}

p {
  font-size: 16px;
}
p a {
  text-decoration: none;
  font-size: 10px;
}</style>

<div class="board">
    <h2>Players:</h2>
    {#each $players.value || [] as player}
        <div>
            <p>
                {player.owner}
                <!--{#if $isAdminPlayer}-->
                <!--    <a href={'#'} on:click={() => removePlayer(player.owner)}> remove </a>-->
                <!--{/if}-->
            </p>
        </div>
    {:else}
        <p>No players yet..</p>
    {/each}
</div>
