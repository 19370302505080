<script lang="ts">import { draws } from '~/contract-methods';
</script>

<style lang="scss">h2 {
  text-decoration: underline;
}

p {
  font-size: 16px;
}</style>

<div class="board">
    <h2>Draws:</h2>

    {#each $draws.value || [] as draw}
        <div>
            <p>
                {draw?.text}
            </p>
        </div>
    {:else}
        <p>Nothing drawn yet..</p>
    {/each}
</div>
