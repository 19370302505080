<script lang="ts">import { session } from '~/session';
import { shuffle } from '~/contract-methods';
let settingBoards = false;
let error = null;
function handleSubmit(event) {
    event.preventDefault();
    settingBoards = true;
    error = null;
    shuffle($session.value)
        .catch((e) => {
        console.log({ e });
        error = e;
    })
        .finally(() => {
        settingBoards = false;
    });
}
</script>

<style lang="scss">form {
  padding: 10px;
  width: 100%;
  background: floralwhite;
  border-top: 0.2em solid var(--primary-color);
}</style>

<div class="post">
    {#if settingBoards}
        <p>Drawing word...</p>
    {:else}
        <form on:submit={handleSubmit}>
            <input type="submit" value="Set Bingo Boards" />
            {#if error}
                <p>Unable to set boards: <b>{error}</b></p>
            {/if}
        </form>
    {/if}
</div>
