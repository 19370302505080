import {writable as writableResult} from 'svelte-result-store'
import {derived} from 'svelte/store'
import {contract} from '~/config'

import {AnyAction, LinkSession, Name} from 'anchor-link'
import {link} from '../session'

import * as types from '../contract-types'

export async function resetGame(session: LinkSession) {
    const data = types.Resetgame.from({
        account: session.auth.actor,
    })
    const action: AnyAction = {
        account: contract,
        name: 'resetgame',
        authorization: [session.auth],
        data,
    }
    try {
        await session.transact({action})
    } catch (error) {
        console.log(`Error on transact: ${(error as Error).message}`)
    }
}

export const game = writableResult<types.GameRow>((set, error) => {
    let timer: any
    var running = true
    const load = () => {
        loadGame()
            .then(({rows}) => {
                set(rows[0])
            })
            .catch(error)
            .finally(() => {
                if (running) {
                    timer = setTimeout(load, 5000)
                }
            })
    }
    load()
    return () => {
        running = false
        clearInterval(timer)
    }
})

function loadGame() {
    return link.client.v1.chain.get_table_rows({
        code: contract,
        table: 'game',
        type: types.GameRow,
        limit: 100,
        key_type: 'i64',
        index_position: 'primary',
    })
}

export const winner = derived([game], ([$game]) => {
    const winnerField = $game.value?.winner || Name.from('')

    return winnerField?.toString() === '' ? null : winnerField
})
