import {AnyAction, LinkSession, UInt64} from 'anchor-link'
import {writable as writableResult} from 'svelte-result-store'

import * as types from '../contract-types'
import {link} from '../session'
import {contract} from '../config'

export async function draw(session: LinkSession, text: string) {
    const data = types.Draw.from({
        account: session.auth.actor,
        text,
    })
    const action: AnyAction = {
        account: contract,
        name: 'draw',
        authorization: [session.auth],
        data,
    }
    try {
        await session.transact({action})
    } catch (error) {
        console.log(`Error on transact: ${(error as Error).message}`)
    }
}

async function loadDraws(lowerBound: UInt64) {
    let response = await link.client.v1.chain.get_table_rows({
        code: contract,
        table: 'draws',
        type: types.DrawRow,
        limit: 100,
        lower_bound: lowerBound,
        key_type: 'i64',
        index_position: 'primary',
    })
    return response
}

export const draws = writableResult<types.DrawRow[]>((set, error) => {
    let headId = UInt64.from(0)
    let timer: any
    var running = true
    const load = () => {
        loadDraws(headId)
            .then(({rows}) => {
                set(rows)
            })
            .catch(error)
            .finally(() => {
                if (running) {
                    timer = setTimeout(load, 5000)
                }
            })
    }
    load()
    return () => {
        running = false
        clearInterval(timer)
    }
})
