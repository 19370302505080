// generated by @greymass/abi2core

import {Int32, Name, Struct, TimePoint, UInt16, UInt64} from '@greymass/eosio'

@Struct.type('addplayer')
export class Addplayer extends Struct {
    @Struct.field(Name) account!: Name
    @Struct.field('bool') admin!: boolean
}

@Struct.type('square')
export class Square extends Struct {
    @Struct.field('string') text!: string
    @Struct.field(UInt16) x!: UInt16
    @Struct.field(UInt16) y!: UInt16
}

@Struct.type('addsquares')
export class Addsquares extends Struct {
    @Struct.field(Name) admin!: Name
    @Struct.field(Name) account!: Name
    @Struct.field(Square, {array: true}) newsquares!: Square[]
}

@Struct.type('winning_square')
export class WinningSquare extends Struct {
    @Struct.field(UInt64) draw_id!: UInt64
    @Struct.field(UInt64) square_id!: UInt64
}

@Struct.type('bingo')
export class Bingo extends Struct {
    @Struct.field(Name) account!: Name
    @Struct.field(Int32) win_type!: Int32
    @Struct.field(WinningSquare, {array: true}) winning_squares!: WinningSquare[]
}

@Struct.type('delplayer')
export class Delplayer extends Struct {
    @Struct.field(Name) account!: Name
}

@Struct.type('draw')
export class Draw extends Struct {
    @Struct.field(Name) account!: Name
    @Struct.field('string') text!: string
}

@Struct.type('draw_row')
export class DrawRow extends Struct {
    @Struct.field(UInt64) id!: UInt64
    @Struct.field('string') text!: string
}

@Struct.type('game_row')
export class GameRow extends Struct {
    @Struct.field(UInt64) id!: UInt64
    @Struct.field(TimePoint) started_at!: TimePoint
    @Struct.field(TimePoint) ended_at!: TimePoint
    @Struct.field(Name) winner!: Name
}

@Struct.type('gamestats')
export class Gamestats extends Struct {
    @Struct.field(UInt64) game_id!: UInt64
    @Struct.field(Name) winner!: Name
    @Struct.field(TimePoint) started_at!: TimePoint
}

@Struct.type('player_row')
export class PlayerRow extends Struct {
    @Struct.field(Name) owner!: Name
    @Struct.field('bool') admin!: boolean
}

@Struct.type('resetgame')
export class Resetgame extends Struct {
    @Struct.field(Name) account!: Name
}

@Struct.type('square_row')
export class SquareRow extends Struct {
    @Struct.field(UInt64) id!: UInt64
    @Struct.field(Name) owner!: Name
    @Struct.field(UInt16) x!: UInt16
    @Struct.field(UInt16) y!: UInt16
    @Struct.field('string') text!: string
}
