export const bingoStrings = [
    'Someone has audio issues....',
    'I need some coffee',
    'Someone talks swedish',
    'All cameras are off',
    'Hold on',
    'Did I freeze?',
    'Project topic Greymass',
    'Children screaming',
    'Project topic Unicove',
    'Dog barks',
    "Aaron's cat makes sounds!",
    'Airplane noises',
    'Scott is sleeping',
    'Someone is early',
    "Someone writes 'I got to go' in chat",
    'We are off topic',
    'Project topic Anchor',
    'Construction noises',
    'Can you see my screen?',
    'Someone is late',
    'Someone cries',
    'Tony does a voice over',
    'Grey enters the chat',
    'All cameras are on',
    'Meeting is going overtime',
]
